/*global window, document, jQuery, setTimeout*/
jQuery(document).ready(function ($) {
    'use strict';

    var alertPopup = $('.alertPopup');

    window.setTimeout(function () {
        alertPopup.removeClass('alertPopup--hidden');

        setTimeout(function () {
            $('.alertPopup-close').trigger('click');
        }, 5000);
    }, 3000);

    $('.alertPopup-close').on('click', function (event) {
        event.preventDefault();
        alertPopup.addClass('alertPopup--hidden');

        setTimeout(function () {
            alertPopup.remove();
        }, 1000);
    });
});
