/*global window,document,jQuery,Foundation*/
jQuery(document).ready(function ($) {
    'use strict';

    var harmonicaForm = $('.HarmonicaForm'),
        harmonicaFormSection = $('.HarmonicaForm-section');

    harmonicaForm.each(function () {
        if ($(this).find('.HarmonicaForm-section:last-child').children('.powermail_privacyFooter') || $(this).find('.HarmonicaForm-section:last-child').children(':submit')) {
            $(this).find('.HarmonicaForm-section:last-child').addClass('is-active');
        }
    });

    harmonicaFormSection.each(function() {
        $(this).find('[required]').not("[name='tx_powermail_pi1[field][land]'], [data-dac-role='city'], [data-dac-role='street'], :disabled").last().addClass('toggleNextSection');
    });

    $('.toggleNextSection').on ('click focus',function() {
        $(this).parents('.HarmonicaForm-section').next('.HarmonicaForm-section').slideDown().addClass('is-active');
    });
});