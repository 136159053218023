// This autocomplete contains 2 very simple API-calls:
// - $(document).trigger('MaxSolrAutocomplete.RemoveDropdown'); // Removed the dropdown completely
// - $(document).trigger('MaxSolrAutocomplete.Initialize', {endpoint: 'url', fieldSelector: 'selector'}); // Initializes all non-initialized autocomplete fields

// Autocompletion will only work on fields that contain the class 'autocomplete-inputField', or are initialized with
// the MaxSolrAutocomplete.Initialize event.

/*global window, document, jQuery, tx_solr_suggestUrl:true*/
var tx_solr_suggestUrl;
tx_solr_suggestUrl = tx_solr_suggestUrl || "";

jQuery(document).ready(function ($) {
    'use strict';

    var SolrAutocomplete = function () {
        this.endpoint = '';
        this.fieldSelector = '';
    };

    SolrAutocomplete.prototype.initialize = function (endpoint, fieldSelector) {
        var self = this;
        this.endpoint = endpoint;
        this.fieldSelector = fieldSelector;

        $(this.fieldSelector).each(function () {
            var field = $(this),
                timeoutId = null,
                previousValue = field.val(),
                keepValue = field.data('reset-value') === 'off';

            if (field.data('MaxSolrAutocomplete-initialized') === true) {
                return;
            }

            field.data('MaxSolrAutocomplete-initialized', true);
            field.attr('autocomplete', 'off');
            field.data('value-at-initialization', $(this).val());

            field.on('click.MaxSolrAutocomplete.inputBarClicked', function () {
                if (!keepValue && field.val() === field.data('value-at-initialization')) {
                    field.val('');
                }
            });

            field.on('blur.MaxSolrAutocomplete.inputBarBlurred', function () {
                if (field.val() === '') {
                    field.val(field.data('value-at-initialization'));
                }
            });

            field.on('keyup.MaxSolrAutocomplete.dataSuggest', function (event) {
                // 38 = KEY UP, 40 = KEY DOWN
                if (event.keyCode !== 38 && event.keyCode !== 40) {

                    // Only continue if the field value has been changed (and not, F.E., the PAGEUP-key has been pushed)
                    if (field.val() === previousValue) {
                        return;
                    }

                    previousValue = field.val();

                    // If a timeout is set, clear it and set a new one later
                    if (timeoutId !== null) {
                        window.clearTimeout(timeoutId);
                        timeoutId = null;
                    }

                    // Not enough data has been filled in, so make sure there's no dropdown and stop the execution of this function
                    if (field.val().length < 3) {
                        self.removeDropdown();
                        return;
                    }

                    // Set a delay and get the data after the timeout has passed
                    timeoutId = window.setTimeout(function () {
                        self.getData(field.val(), $('.tx-solr input[name="L"]').val(), function (data) {
                            self.showDropdown(field, data);
                        });
                    }, 400);
                } else {
                    event.preventDefault();

                    if (event.keyCode === 38) {
                        // Up key, select previous element
                        self.selectPreviousDropdownItem();
                    } else {
                        // Down key, select next element
                        self.selectNextDropdownItem();
                    }
                }
            });
        });
    };

    SolrAutocomplete.prototype.getData = function (searchterm, language, callbackFunction) {
        var self = this;
        $.getJSON(
            self.endpoint,
            {
                termLowercase: searchterm.toLowerCase(),
                termOriginal: searchterm,
                L: language
            },
            function (data) {
                var output = [];

                $.each(data, function (term, termIndex) {
                    var unformatted_label = term;
                    // append the URL property if the termIndex is not an integer
                    output.push({
                        label: unformatted_label.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' +
                            searchterm.replace(/[\-\[\]{}()*+?.,\^$|#\s]/g, "\\$&") +
                            ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<strong>$1</strong>'),
                        value: term,
                        url: parseInt(termIndex, 10) !== termIndex ? termIndex : null
                    });
                });

                callbackFunction(output);
            }
        );
    };

    SolrAutocomplete.prototype.showDropdown = function (inputElement, data) {

        if (!data || data.length === 0) {
            return;
        }

        var self = this,
            list = $('<ul class="solrAutocomplete"></ul>'),
            listItem,
            label;

        // Remove every possible previous dropdown
        self.removeDropdown();

        if (inputElement.attr('id')) {
            list.attr('data-relatedinputnode', inputElement.attr('id'));
        }

        // Add event listeners for the recent created list
        list.on('click.MaxSolrAutocomplete.itemClick', '.solrAutocomplete-item', function () {
            var $listItem = $(this);

            // Fill the parent with the clicked value, submit the form and remove the dropdown
            inputElement.val($listItem.data('value'));

            // submit the form only if URL is not set, otherwise jump directly to the provided URL
            if ($listItem.data('url') !== null) {
                window.location = $listItem.data('url');
            } else {
                inputElement.parents('form').first().trigger('submit');
            }

            self.removeDropdown();
        });

        list.on('mouseover.MaxSolrAutocomplete.itemHover', '.solrAutocomplete-item', function () {
            list.children().removeClass('is-active');
            $(this).addClass('is-active');
        });

        $.each(data, function (index, row) {
            label = $('<span class="solrAutocomplete-item-label"></span>').append(row.label);
            // add the full record as .data of the element for later use
            listItem = $('<li class="solrAutocomplete-item"></li>').data(row);
            list.data('inputElement', inputElement);

            listItem.append(label);
            list.append(listItem);
        });

        inputElement.off('keydown.MaxSolrAutocomplete').on('keydown.MaxSolrAutocomplete', function (event) {
            // keyCode 13 === enter key
            if (event.keyCode === 13) {
                var $activeElement = list.find('.is-active');
                if ($activeElement.length === 0 && list.find('.solrAutocomplete-item').length === 1) {
                    $activeElement = list.find('.solrAutocomplete-item:first-child');
                }

                if ($activeElement.length === 1 && $activeElement.data('url') !== undefined) {
                    window.location = $activeElement.data('url');
                }
            }
        });

        inputElement.off('blur.MaxSolrAutocomplete.blur').on('blur.MaxSolrAutocomplete.blur', function () {
            // self.removeDropdown();
        });

        // Reposition the dropdown after each window resize
        $(window).off('resize.MaxSolrAutocomplete').on('resize.MaxSolrAutocomplete', function () {
            self.positionDropdown(list, inputElement);
        });

        $(document).off('click.MaxSolrAutocomplete.LoseFocus').on('click.MaxSolrAutocomplete.LoseFocus', function (event) {
            if ($(event.target).is(inputElement) === false) {
                self.removeDropdown();
            }
        });

        // Put the list on the right position and append it to the body
        self.positionDropdown(list, inputElement);
        $('body').append(list);
    };

    SolrAutocomplete.prototype.positionDropdown = function (dropdown, relativeTo) {
        dropdown.css({
            top: (relativeTo.offset().top + relativeTo.outerHeight()),
            left: relativeTo.offset().left,
            width: relativeTo.outerWidth()
        });
    };

    SolrAutocomplete.prototype.selectNextDropdownItem = function () {
        var currentlySelectedItem = $('.solrAutocomplete .solrAutocomplete-item.is-active'),
            nextItem = null;

        if (currentlySelectedItem.length === 0 || $('.solrAutocomplete .solrAutocomplete-item').last().is(currentlySelectedItem)) {
            nextItem = $('.solrAutocomplete .solrAutocomplete-item').first();
        } else {
            nextItem = currentlySelectedItem.next();
        }

        this.focusDropdownItem(nextItem);
    };

    SolrAutocomplete.prototype.selectPreviousDropdownItem = function () {
        var currentlySelectedItem = $('.solrAutocomplete .solrAutocomplete-item.is-active'),
            prevItem = null;

        if (currentlySelectedItem.length === 0 || $('.solrAutocomplete .solrAutocomplete-item').first().is(currentlySelectedItem)) {
            prevItem = $('.solrAutocomplete .solrAutocomplete-item').last();
        } else {
            prevItem = currentlySelectedItem.prev();
        }

        this.focusDropdownItem(prevItem);
    };

    SolrAutocomplete.prototype.focusDropdownItem = function (item) {
        $('.solrAutocomplete .solrAutocomplete-item.is-active').removeClass('is-active');
        item.addClass('is-active');

        $($('.solrAutocomplete').data('inputElement')).val(item.data('value'));
    };

    SolrAutocomplete.prototype.removeDropdown = function () {
        $('.solrAutocomplete').remove();
    };

    $(document).off('MaxSolrAutocomplete.RemoveDropdown').on('MaxSolrAutocomplete.RemoveDropdown', function () {
        $('.solrAutocomplete').remove();
    });

    $(document).off('MaxSolrAutocomplete.Initialize').on('MaxSolrAutocomplete.Initialize', function (event, params) {
        new SolrAutocomplete().initialize(params.endpoint, params.fieldSelector);
    });

    // Add the class autocomplete-inputField to all solr input fields and then initialize SolrAutocomplete
    $('.tx-solr-q').addClass('autocomplete-inputField');
    new SolrAutocomplete().initialize(window.maxSolrAutoCompleteEndPoint || tx_solr_suggestUrl, '.autocomplete-inputField');
});