(function () {
    class Accordion {
        getAdditionalVerticalSpace(element, prop) {
            if (!element || !prop) return 0;
            const styles = window.getComputedStyle(element, null);
            return parseFloat(styles.getPropertyValue(prop));
        }

        constructor(el) {
            this.el = el;
            this.summary = el.querySelector('summary');
            this.content = el.querySelector('.js-mx_accordion__description');
            this.buttonList = el.querySelector('.elementButtonList');
            const container = el.closest('.js-mx_accordion__container');

            // Animation duration overwritable by TYPO3 constants.
            this.animationDuration = Number(container.dataset.animationDuration || '300');
            this.animationEasing = container.dataset.animationEasing || 'ease-out';

            // Try to get the bottom padding.
            // When a bottom padding is enabled on the accordion element,
            // we need to take the padding into account when performing the animation.
            this.additionalPadding = () => parseFloat(this.getAdditionalVerticalSpace(this.el, 'padding-bottom'));

            // Store the animation object (so we can cancel it if needed)
            this.animation = null;
            // Store if the element is closing
            this.isClosing = false;
            // Store if the element is expanding
            this.isExpanding = false;
            // Detect user clicks on the summary element
            this.summary.addEventListener('click', (e) => this.onClick(e));
        }

        getTotalHeightWithMargin(element) {
            if (!element) return 0;

            // Get the DOM Node if you pass in a string
            element = (typeof element === 'string') ? document.querySelector(element) : element;

            // Get computed styles
            const styles = window.getComputedStyle(element, null);

            // Add margin to offsetHeight
            const marginTop = parseFloat(styles.getPropertyValue('margin-top'));
            const marginBottom = parseFloat(styles.getPropertyValue('margin-bottom'));

            // Padding are already included in the offsetHeight property.
            return element.offsetHeight + marginTop + marginBottom;
        }

        onClick(e) {
            e.preventDefault();
            // Add an overflow on the <details> to avoid content overflowing
            this.el.style.overflow = 'hidden';

            if (this.isClosing || !this.el.open) {
                this.open();

            } else if (this.isExpanding || this.el.open) {
                this.shrink();
            }
        }

        shrink() {
            this.isClosing = true;

            // Store the current height of the element
            const startHeight = `${this.el.offsetHeight}px`;

            // Calculate the height of the summary
            const endHeight = `${this.summary.offsetHeight + this.additionalPadding()}px`;

            // If there is already an animation running
            if (this.animation) {
                this.animation.cancel();
            }

            // Start a WAAPI animation
            this.animation = this.el.animate({
                // Set the keyframes from the startHeight to endHeight
                height: [startHeight, endHeight]
            }, {
                duration: this.animationDuration,
                easing: this.animationEasing
            });

            this.animation.onfinish = () => this.onAnimationFinish(false);
            this.animation.oncancel = () => this.isClosing = false;
        }

        open() {
            this.el.style.height = `${this.el.offsetHeight}px`;
            // Force the [open] attribute on the details element
            this.el.open = true;
            // Wait for the next frame to call the expand function
            window.requestAnimationFrame(() => this.expand());
        }

        expand() {
            this.isExpanding = true;
            // Get the current fixed height of the element
            const startHeight = `${this.el.offsetHeight}px`;
            // Calculate the open height of the element (summary height + content height)
            const endHeight = `${
                this.getTotalHeightWithMargin(this.summary)
                + this.getTotalHeightWithMargin(this.content)
                + this.additionalPadding()
                + this.getTotalHeightWithMargin(this.buttonList)
            }px`;

            // If there is already an animation running cancel the current animation.
            if (this.animation) {
                this.animation.cancel();
            }

            // Start a WAAPI animation
            this.animation = this.el.animate({
                // Set the keyframes from the startHeight to endHeight
                height: [startHeight, endHeight]
            }, {
                duration: this.animationDuration,
                easing: this.animationEasing
            });

            this.animation.onfinish = () => this.onAnimationFinish(true);
            // If the animation is cancelled, isExpanding variable is set to false
            this.animation.oncancel = () => this.isExpanding = false;
        }

        onAnimationFinish(open) {
            this.el.open = open;
            this.animation = null;
            this.isClosing = false;
            this.isExpanding = false;
            // Remove the overflow hidden and the fixed height
            this.el.style.height = '';
            this.el.style.overflow = '';
        }
    }

    document.querySelectorAll('.js-mx_accordion__details').forEach((el) => {
        new Accordion(el);
    });
})();
