document.addEventListener('DOMContentLoaded', function () {
    const readMoreTexts = document.querySelectorAll('.js-read-more-check');
    const readMoreLinks = document.querySelectorAll('.js-read-more-label');

    if (readMoreTexts.length === 0) return;

    function updateReadMoreVisibility() {
        readMoreTexts.forEach((readMoreText, index) => {
            const readMoreLink = readMoreLinks[index];
            const computedStyle = getComputedStyle(readMoreText);
            const scrollHeight = readMoreText.scrollHeight;
            const elementHeight = parseFloat(computedStyle.height) + parseFloat(computedStyle.marginBottom);

            const isOverflowing = scrollHeight > (elementHeight + 1); // +1 since scrollHeight and elementHeight can be equal on desktop resolutions

            if (isOverflowing) {
                readMoreLink.style.visibility = 'visible';
            } else {
                readMoreLink.style.visibility = 'hidden';
            }
        });
    }

    function debounceReadMoreVisibility(func, wait) {
        let timeout;

        return function() {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(), wait);
        };
    }

    const debouncedResize = debounceReadMoreVisibility(updateReadMoreVisibility, 100);

    window.addEventListener('resize', debouncedResize);

    updateReadMoreVisibility();
});
