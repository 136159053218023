/*global document,jQuery*/
jQuery(document).ready(function ($) {
    "use strict";

    var scrollHeader = $('.maxScrollHeader');

    $(document).on('MaxServ.ScrollHeader.MenuHidden', function () {
        scrollHeader.removeClass('is-active');
    });

    $(document).on('MaxServ.ScrollHeader.MenuVisible', function () {
        scrollHeader.addClass('is-active');
    });
});
