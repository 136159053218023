/*global window, document, jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.js-printButton').on('click', function (event) {
        event.preventDefault();

        window.print();
    });
});
