/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    if ($('.ImageStack').length > 0) {
        $('.ImageStack').each(function () {
            var items = $(this).find(".ImageStack-list-item"); // selector
            var size_li = items.size(); // get items size
            var init = 2; // start with number
            var show = 2; // how many must shown after click

            // Initialize the first 2
            showItems(init);

            if($(this).find('.ImageStack-more').length > 0) {
                // When button is clicked show next 2
                $(this).find('.ImageStack-more').on('click',function () {
                    init = (init + show <= size_li) ? init + show : size_li;
                    showItems(init);

                    if (size_li < init + show) {
                        $(this).hide();
                    }
                });
            }

            function showItems(init) {
                // Select the first init(number)
                items.slice(0, init).each(function() {
                    $(this).removeClass('ImageStack-list-item--hide'); // Remove class form list item
                    if (!$(this).find('img').is('[src]')) { // check if img has attribute src
                        $(this).find('img').attr("src", $(this).find('img').data('src')); // Change data-src to src
                        $(this).find('img').attr("srcset", $(this).find('img').data('srcset')); // Change data-srcset to srcset
                    }
                });
            }
        });
    }
});
