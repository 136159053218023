/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.reviewTabSlider-for').slick({
        centerMode: true,
        centerPadding: '0px',
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        draggable: false,
        asNavFor: '.reviewTabSlider-nav'
    });

    $('.reviewTabSlider-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.reviewTabSlider-for',
        dots: false,
        arrows: false
    });
});
