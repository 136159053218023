/*global window, document, jQuery, Foundation*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.openingHours').each(function () {

        var $searchMultipleWeeks = $(this).find('.openingHours-multipleWeeks'),
            $activeWeek = $(this).find('.openingHours-weekActive'),
            $sliderActiveWeek = $(this).find('.openingHours-sliderActiveWeek');

        $(this).find('.openingHours-sliderArrowRight').on('click', function () {
            if ($activeWeek.not('.last-week')) {
                $($searchMultipleWeeks).addClass('openingHours-translateLeft');
                if ($activeWeek.not('.first-week')) {
                    $sliderActiveWeek.removeClass('is-first');
                }
            }
        });

        $(this).find('.openingHours-sliderArrowLeft').on('click', function () {
            if ($activeWeek.not('.first-week')) {
                $($searchMultipleWeeks).removeClass('openingHours-translateLeft');
                if ($activeWeek.not('.last-week')) {
                    $sliderActiveWeek.addClass('is-first');
                }
            }
        });
    });
});
