/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    // This file has to be loaded BEFORE MaxScrollHeader.js, because MaxScrollHeader doesn't contain any
    // function or trigger to call. It justs starts at the DocumentReady event, so make sure that before
    // MaxScrollHeader gets activated, you first add the .maxScrollHeader container to your site

    // PLEASE NOTE: this code is PROJECT SPECIFIC CODE. Don't simply copy it, but first check if fits your purposes.
    // The line below only copies the pageHeader, sets the maxScrollHeader class and inserts it before off-canvas-wrap
    //
    // It could be possible that your scrollHeader's DOM structure needs to be modified, before the MaxScrollHeader.js
    // script is activated. (E.g. remove header contents, flatten a menu, ...) Do that kind of actions in this file

    var pageHeaderClone = $('.js-menuClone').clone().addClass('pageHeader maxScrollHeader')

    pageHeaderClone.removeAttr('data-test');
    pageHeaderClone.find('[data-test]').removeAttr('data-test');

    pageHeaderClone.insertBefore($('.pageWrapper'));

    $('.maxScrollHeader .pageHeader-search').remove();
});
