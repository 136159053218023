/*global document,jQuery,$.magnificPopup*/
jQuery(document).ready(function ($) {
    'use strict';
    $('.js-openPopup').on('click', function () {
        $.magnificPopup.open({
            items: {
                src: $(this).attr('href')
            },
            type: 'inline',
            showCloseBtn: false,
            alignTop: true,
            mainClass: 'is-light is-width80 is-offSetTop100',
            fixedContentPos: false,
            callbacks: {
                open: function () {
                    // Cuz Magnific disables page scrolling by default and adds some margin to compensate with the scrollbar,
                    // we over ride these css properties to re enable page scrolling:
                    $('html').css({
                        'overflow': 'auto',
                        'margin-right': 'auto'
                    });
                }
            }
        });
    });
});
