/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    var toggleLink = $('.js-toggleTable');

    toggleLink.on('click',function (e) {
        var currentBtnText = $(this).text(),
            newBtnText = $(this).attr('data-toggle-table-label');

        e.preventDefault();

        $(this).siblings('.js-productRules').find('.js-abstract').toggleClass('is-active');
        $(this).text(newBtnText).attr('data-toggle-table-label', currentBtnText).toggleClass('is-active');
    });
});
