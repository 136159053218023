(function($) {
    var active = false;

    $.each($('.js-linkgroup'), function(i, element) {
        $(element).on('focus active click',function() {
            active = false;
            redirectToPage($(this));
        }).on('change blur', function() {
            active = true;
            redirectToPage($(this));
        });
    });

    function redirectToPage(el) {
        if (active) {
            window.location = el.val();
            active = false;
        } else {
            active = true;
        }
    }

})(jQuery);
