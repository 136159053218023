/*global jQuery, document, window*/
jQuery(document).ready(function ($) {
    'use strict';

    $('a.shareButtons-button').on('click.MaxServ.OpenSocialSharePopup', function (event) {
        event.preventDefault();

        var url = $(this).attr('href');
        if (url.substring(0, 7) === 'mailto:') {
            window.location.href = url;
        } else {
            window.open(url, '', 'height=320, width=640, toolbar=no, menubar=no, location=no, directories=no');
        }
    });
});