/*global document, jQuery, window*/
jQuery(function ($) {
    'use strict';

    $(document).on('change', '[data-lookup-select-url-value]', function () {
        var $select = $(this),
            currentValue = $select.val();

        if (currentValue !== undefined && currentValue !== '') {
            window.location = currentValue;
        }
    });
});