/*global jQuery, window*/

jQuery(function ($) {
    'use strict';

    var fieldValues = {},
        serializedFormValues;

    // try to resolve form values from the hash part of the URL
    serializedFormValues = decodeURIComponent(window.location.hash.substring(1)).split('&');

    $.each(serializedFormValues, function (ignore, keyValueString) {
        var keyValue = keyValueString.split('=');

        fieldValues[keyValue[0]] = decodeURI(keyValue[1]).replace(/[\\+]/g, ' ');
    });

    $('.powermail_form').each(function () {
        var $form = $(this);

        $.each(fieldValues, function (fieldName, fieldValue) {
            if (fieldName !== undefined && fieldName !== null && fieldName !== '') {
                // the name attribute has an application specific prefix,
                // but the last segment between brackets is the readable name
                var $field = $form.find('[name$="\\[' + fieldName + '\\]"]');

                $field.val(fieldValue);

                // dispatch a change event for this field to notify possible observers from custom validators
                $field.trigger('change');
            }
        });
    });
});