(function() {
    if ($('.ProductHeaderImage').length) {
        $('.ProductHeaderImage').magnificPopup({
            delegate: 'a.image-link',
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
})();
