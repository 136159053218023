/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var filters = $(".lookup-usedFilters .lookup-usedFilters-filter"),
        todo = [];

    filters.each(function () {
        var title = $(this).text().trim();
        if (todo.indexOf(title) === -1) {
            todo.push(title);
        }

    });

    $(".lookup-facet-FieldFacet li a").each(function () {
        var link = $(this),
            title = link.text().trim();
        if (todo.indexOf(title) !== -1) {
            link.parent().addClass("is-active");
        }
    });

});
