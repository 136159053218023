/*global document, jQuery, $.magnificPopup*/

jQuery(document).ready(function ($) {
    'use strict';

    $('.js-popupGallery').each(function () {
        $(this).magnificPopup({
            delegate: '.js-popupGallery-link',
            type: 'image',
            fixedContentPos: true,
            gallery: {
                enabled: true,
            },
            callbacks: {
                buildControls: function () {
                    this.contentContainer.find('figcaption').remove();
                    if (this.items.length > 1) {
                        // re-appends controls inside the main container
                        this.contentContainer.find('.mfp-figure').append(this.arrowLeft.add(this.arrowRight));
                    }
                },

                open: function () {
                    // Remove margin right that was set by magnific by default
                    $('html').css({
                        'overflow': 'auto',
                        'margin-right': 'auto'
                    });
                }
            }
        });
    });

    $('.js-popupGallery-referenceLink').on('click',function (e) {
        e.preventDefault();

        var parentSlider = $(this).parents('.js-popupGallery'),
            currentImageIndex = parseInt(parentSlider.find('.glide__bullet--active').attr('data-glide-dir').replace('=', ''), 10) + 1;

        if ($(this).get(0).hasAttribute('data-popup-gallery-goto')) {
            currentImageIndex = parseInt($(this).attr('data-popup-gallery-goto'), 10) + 1;
        }

        if (parentSlider.length) {
            parentSlider.magnificPopup('open', currentImageIndex);
        }
    });
});
