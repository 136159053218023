/*global document*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    var toggleOrderElements = document.querySelectorAll('.js-toggle-order'),
        selectField = document.querySelector('.js-toggle-order-select'),
        classToToggle = 'u-hidden';

    if (toggleOrderElements && selectField) {
        var currentOrder = selectField.options[ selectField.selectedIndex ].value;
        document.querySelector('[data-toggle-order="' + currentOrder + '"]').classList.remove(classToToggle);

        for (var i = 0; i < toggleOrderElements.length; i++) {
            toggleOrderElements[i].addEventListener('click', function (e) {
                e.preventDefault();

                var switchTo = this.getAttribute('data-toggle-order-switch-to');

                this.classList.add(classToToggle);
                toggleSelect(switchTo, classToToggle);
            })
        }
    }

    function toggleSelect(switchTo, classToToggle) {
        document.querySelector('[data-toggle-order="' + switchTo + '"]').classList.remove(classToToggle);
        document.querySelector('.js-toggle-order-select').value = switchTo;
        document.querySelectorAll('.js-submit-on-change').forEach(function(element) {
            var event = new Event('change');
            element.dispatchEvent(event);
        });

    }
});
