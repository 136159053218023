/*global document*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    var toggleClassElements = document.querySelectorAll('.js-toggle-class');

    for (var i = 0; i < toggleClassElements.length; i++) {
        toggleClassElements[i].addEventListener('click', function (e) {
            e.preventDefault();

            var classToToggle = this.getAttribute('data-toggle-class');

            if (this.classList.contains(classToToggle)) {
                this.classList.remove(classToToggle);
            } else {
                this.classList.add(classToToggle);
            }
        })
    }
});
