/*global window, document, jQuery*/

(function ($) {
    'use strict';

    var componentDivider = ', ',
        exitLabel = 'Wijzigen',
        prepareGroup,
        widgetTemplate;

    widgetTemplate = '<div class="addressCompletionWidget" data-acw>'
        + '<div class="row">'
        + '<div class="small-9 columns">'
        + '<div class="addressCompletionWidget-address addressCompletionWidget-address--empty">'
        + '<img class="addressCompletionWidget-loadingIcon" src="/typo3conf/ext/site_kkn_fe/Resources/Public/Images/ajax-loader.gif" alt="Adres wordt opgezocht..." />'
        + '<span class="addressCompletionWidget-address-component addressCompletionWidget-address-component--street" data-acw-street></span>'
        + '<span class="addressCompletionWidget-address-component addressCompletionWidget-address-component--divider">' + componentDivider + '</span>'
        + '<span class="addressCompletionWidget-address-component addressCompletionWidget-address-component--city" data-acw-city></span>'
        + '</div>'
        + '</div>'
        + '<div class="small-3 columns">'
        + '<button class="addressCompletionWidget-button" data-acw-exit>' + exitLabel + '</button>'
        + '</div>'
        + '</div>'
        + '</div>';

    /**
     *
     * @param {jQuery} $ancestor a shared container to indicate the location of both fields
     * @param {jQuery} $fieldset
     */
    prepareGroup = function ($ancestor, $fieldset) {
        var $fields = $ancestor.find('[data-dac-role="street"], [data-dac-role="city"]'),
            $cityLabel,
            $streetLabel,
            groupWidgetTemplate = widgetTemplate;

        // If the addressCompletionWidget is positioned inside an aquatic panel, add an extra classname to the element
        if ($ancestor.closest('.directionsForm').length > 0 || $ancestor.closest('.coloredContainer--grey').length > 0) {
            groupWidgetTemplate = groupWidgetTemplate.replace('class="addressCompletionWidget"', 'class="addressCompletionWidget addressCompletionWidget--onLightGreyBackground"');
        }

        // Always use the lightGreyBackground loader.
        groupWidgetTemplate = groupWidgetTemplate.replace('ajax-loader.gif', 'ajax-loaderLightGreyBackground.gif');

        $ancestor.after(groupWidgetTemplate);

        $cityLabel = $fieldset.find('[data-acw-city]');
        $streetLabel = $fieldset.find('[data-acw-street]');

        // if a field is changed transfer the value to the label
        $fields.on('change', function () {
            var $field = $(this);

            if ($field.is('[data-dac-role="city"]')) {
                $cityLabel.text($field.val());
            } else if ($field.is('[data-dac-role="street"]')) {
                $streetLabel.text($field.val());
            }

            $fieldset.find('.addressCompletionWidget-address').removeClass('addressCompletionWidget-address--empty');
        });
    };

    $(document).on('MaxServ_DutchAddressCompletion_Initialize', function () {
        // Note that the keyup event is removed from the $dacItems on the lines below
        // So if you need to add extra items to this selector, notice that it won't contain a keyup handler
        var $dacItems = $('[data-dac-role="street"], [data-dac-role="city"]');

        // Remove the keyup event from the dac items, so it's possible to modify your street/city without starting a new
        // xhr request (which could override your input)
        window.setTimeout(function () {
            $dacItems.off('keyup');
        });

        $dacItems.each(function () {
            var $ancestor = $(this).parentsUntil('fieldset').last(),
                $fieldset = $ancestor.parent('fieldset'),
                $widget;

            if (!$fieldset.attr('data-acw-init')) {
                $fieldset.attr('data-acw-init', true);

                prepareGroup($ancestor, $fieldset);

                $widget = $fieldset.find('[data-acw]');
            }

            if ($widget !== undefined && $widget.length === 1) {
                // if the widget element is initialized hide the regular fields
                $ancestor.addClass('hide');

                // if a user exits the widget show the regular fields and remove the widget component
                $widget.find('[data-acw-exit]').on('click', function (event) {
                    event.preventDefault();

                    $ancestor.removeClass('hide');
                    $widget.remove();
                });

                // if automatic resolving fails exit the widget
                $(document).on('MaxServ_DutchAddressCompletion_FailedRequest', function () {
                    $widget.find('[data-acw-exit]').trigger('click');
                });
            }
        });
    });
}(jQuery));
