/*global require, module, window*/

// Register PowermailTabs module
(function (window, factory) {
    if ( typeof define === "function" && define.amd) {
        // requirejs
        define("PowermailTabs", ["jquery"], function (jQuery) {
            return factory(window, jQuery);
        });
    } else if ( typeof module === "object" && module.exports ) {
        // CommonJS
        module.exports = factory(
            window,
            require("jquery")
        );
    } else {
        // browser global
        window.PowermailTabs = factory(
            window,
            window.jQuery
        );
    }
}(window, function (window, jQuery) {
    "use strict";

    var PowermailTabs = function (form, options) {
        var self = this;

        self.currentTabIndex = 0;
        self.form = jQuery(form);
        self.options = options;
        self.fieldsets = self.form.find("fieldset");
        self.unlockedTabs = 0;

        self.initialize = function () {
            self.initElements();
            self.initListeners();
            self.goToTab(0);

            if (self.options.equalizeTabs) {
                var heights = [];
                self.tabs.each(function () {
                    heights.push(jQuery(this).height());
                });

                self.tabs.css('min-height', Math.max.apply(null, heights));
            }
        };

        self.initElements = function () {
            self.tabs = self.form.find(".MultistepForm-page");
            self.pager = self.form.find(".MultistepFormPager");
            self.pages = self.pager.children();
        };

        self.initListeners = function () {
            self.form
                .on("click", ".MultistepForm-page.is-active .MultistepForm-nextButton", function (e) {
                    e.preventDefault(0);
                    self.finishTab();
                })
                .on("click", ".MultistepForm-page.is-active .MultistepForm-prevButton", function (e) {
                    e.preventDefault(0);
                    self.goToTab(self.currentTabIndex - 1);
                })
                .on("click", ".MultistepForm-navigationLink", function (e) {
                    e.preventDefault();
                    var pageIndex = jQuery(this).data("page-index");

                    if (pageIndex <= self.currentTabIndex) {
                        self.goToTab(pageIndex);
                    }

                    if (pageIndex === self.currentTabIndex + 1) {
                        self.finishTab();
                    }
                });
        };

        self.goToTab = function (index) {
            // check if index exists
            if (index >= self.tabs.length) {
                return;
            }

            var alreadyHadActiveTab = (typeof self.active === 'object'),
                elements = {
                    tabs: self.tabs,
                    pages: self.pages
                }, key, element;
            self.active = {};

            if (self.isTabUnlocked(index)) {
                for (key in elements) {
                    element = elements[key];

                    // set states
                    element
                        .toggleClass("is-unlocked", false)
                        .toggleClass("is-active", false)
                        .slice(0, self.unlockedTabs + 1)
                        .toggleClass("is-unlocked", true);

                    self.active[key] = element.slice(index, index + 1).toggleClass("is-active", true);

                };
                self.currentTabIndex = index;

                if (alreadyHadActiveTab) {
                    var currentScroll = window.pageYOffset,
                        windowHeight = jQuery(window).height(),
                        pagerStart = self.pager.offset().top;

                    if (pagerStart < currentScroll || pagerStart > (currentScroll + windowHeight)) {
                        jQuery('html, body').animate({scrollTop: pagerStart});
                    }
                }
            }
        };

        self.finishTab = function () {
            var valid = self.validateTab(self.currentTabIndex);

            if (valid) {
                self.unlockedTabs = self.currentTabIndex + 1;
                self.goToTab(self.currentTabIndex + 1);
            }
        };

        self.validateTab = function (tabIndex) {
            var valid = true,
                fields = self.tabs.slice(tabIndex, tabIndex + 1).find(":input[required]:visible");

            if(fields.length === 0) {
                fields = self.tabs.slice(tabIndex, tabIndex + 1).find("[data-parsley-required]:visible");
            }

            fields.each(function (ignore, field) {
                var status = jQuery(field).parsley().validate();
                valid = valid && status === true;
            });

            return valid;
        }

        self.isTabUnlocked = function (index) {
            return index <= self.unlockedTabs;
        };

        self.initialize();
    };

    return PowermailTabs;
}));

// Register jQuery plugin
(function (window, factory) {
    if ( typeof require === "function") {
        // requirejs
        require(["jquery", "PowermailTabs"], function (jQuery, PowermailTabs) {
            factory(window, jQuery, PowermailTabs);
        });
    } else if ( typeof module === "object" && module.exports ) {
        // CommonJS
        factory(
            window,
            require("jquery"),
            require("PowermailTabs")
        );
    } else {
        // browser global
        factory(
            window,
            window.jQuery,
            window.PowermailTabs
        );
    }
}(window, function (window, jQuery, PowermailTabs) {
    "use strict";

    var multiStepFormConfig = window.multiStepFormConfig || {};
    jQuery.fn.powermailTabs = function (options) {

        options = jQuery.extend({}, multiStepFormConfig, options);

        return jQuery(this).each(function () {
            var $this = jQuery(this);
            if (!$this.data("powermail_tabs")) {
                $this.data("powermail_tabs", new PowermailTabs($this, options));
            }
        });
    };

    jQuery(".powermail_morestep").each(function(){
        jQuery(this).powermailTabs()
    })
}));
