(function () {
    document.querySelectorAll('.js-mx_accordion__summary')
        .forEach(element => {
            element.addEventListener('click', (event) => {
                var summaryElement = event.target,
                    containerElement = summaryElement.closest('.js-mx_accordion__container'),
                    detailsElements = containerElement.querySelectorAll('.js-mx_accordion__details'),
                    currentDetails = summaryElement.closest('.js-mx_accordion__details');

                event.preventDefault();

                for (var i = 0; i < detailsElements.length; i++) {
                    if (detailsElements[i] === currentDetails) {
                        continue;
                    }

                    if (detailsElements[i].open) {
                        detailsElements[i].children[0].click();
                    }
                }
            });
        });
})();
