/*global document,jQuery, window*/
jQuery(document).ready(function ($) {
    'use strict';

    var navigatorShareBtn = $('.js-navigatorShare-btn'),
        navigatorShareBtnFallBackWrapper = $('.js-navigatorShare-fallBack'),
        pageUrl = window.location.href,
        // Assign these in your project specific files, otherwise a fallback will be used:
        pageTitle = $('.js-navigatorShareTitle').first().text(),
        pageText = $('.js-navigatorShareText').first().text().split(/\s+/).slice(0, 20).join(' ');

    navigatorShareBtn.click(function (e) {
        e.preventDefault();

        if (!pageTitle.length) {
            // Fallback title
            pageTitle = $(this).attr('data-navigatorshare-fallbacktitle');
        }

        if (!pageText.length) {
            // Fallback text
            pageText = $(this).attr('data-navigatorshare-fallbacktext');
        }

        if (navigator.share) {
            navigator.share({
                title: pageTitle,
                text: pageText + '...',
                url: pageUrl,
            });
        } else {
            $(this).addClass('hide');
            $(this).parents('.js-navigatorShare').find(navigatorShareBtnFallBackWrapper).removeClass('hide');
        }
    });
});
