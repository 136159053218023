/*global window,document,venue*/

// This javascript lib searches for elements with the data-attribute "data-showopeningforvenue", this data-attribute
// should contain a venue ID. A label will be added to this element, which indicates when the venue will be opened.
// Fluid template example: <span class="nowOpenLabel" data-showopeningforvenue="{venue.uid}"></span>
// The element should be empty by default.
// Also make sure that the venue.openingHours is filled by the nowOpenLabelJsonViewHelper. This can be done in two ways:
// {venue -> venue:opening.nowOpenLabelJson()} for a single venue object
// or
// <venue:dataWidget.venue action="list" as="venues">{venues -> venue:opening.nowOpenLabelJson()}</venue:dataWidget.venue>
// to retrieve a full list of venues
jQuery(document).ready(function ($) {
    'use strict';

    if (!window.venue || !venue.openingHours || !venue.openingHourLabels) {
        return;
    }

    var dataAttribute = 'data-showopeningforvenue',
        openingHoursPerVenue = {},
        dates = {},
        dateObj = new Date(),
        isVenueOpen,
        getLabel;

    // Create an object of dates, which have same format as in the NowOpenLabelJsonViewHelper: Y-m-d without preceding zero's
    dates.now = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate();
    dates.today = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate();

    dateObj.setDate(dateObj.getDate() + 1);
    dates.tomorrow = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate();

    dateObj.setDate(dateObj.getDate() + 1);
    dates.dayAfterTomorrow = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate();

    // There are probably multiple multiple venue content elements on the page
    // which generate a venueOpeningHours array. Each CE adds its own item to this array.
    // Merge all this data into a single object.
    venue.openingHours.forEach(function (openingHours) {
        Object.keys(openingHours).forEach(function (key) {
            if (openingHoursPerVenue[key] === undefined) {
                openingHoursPerVenue[key] = openingHours[key];
            }
        });
    });

    isVenueOpen = function (venueOpeningHours, day) {
        if (!venueOpeningHours || !venueOpeningHours[dates[day]]) {
            return false;
        }

        var today = new Date().setUTCHours(0, 0, 0, 0),
            now = Date.now() / 1000 - today / 1000,
            open = venueOpeningHours[dates[day]]['open'],
            close = venueOpeningHours[dates[day]]['close'];

        if (open === 0) {
            // Open is zero, so the venue is closed
            return false;
        } else if (day === 'now') {
            // Indicates whether the venue is open NOW
            return now > open && now < close;
        } else if (day === 'today') {
            // Indicates whether the venue will be open LATER today
            return now < open;
        } else if (day === 'tomorrow' || day === 'dayAfterTomorrow') {
            // Venue isn't open now/later today. A couple of lines back we've already checked whether the venue is
            // open on the given Day variable. So in case we're checking tomorrow/dayAfterTomorrow and we haven't
            // returned false yet, simply return true since the venue is opened (which we checked earlier)
            return true;
        } else {
            // unknown day, return false
            return false;
        }
    };

    // Returns a fully formatted label containing the openLabel, open and close time
    // If isToday is true, the openLabel will be wrapped inside a span that can be styled separately from the rest of the text
    getLabel = function (todaysOpeningHours, openLabel, isToday) {
        var label,
            openFrom = new Date(todaysOpeningHours.open * 1000),
            openFromLabel,
            closedAt = new Date(todaysOpeningHours.close * 1000),
            closedAtLabel;

        if (isToday === true) {
            label = '<span class="nowOpenLabel--colored">' + openLabel + ':</span> ';
        } else {
            label = openLabel + ': ';
        }

        // Make sure the hours and minutes have a preceding 0 when they're lower than 10
        openFromLabel =
            (openFrom.getHours() < 10 ? '0' : '') +
            openFrom.getHours() +
            '.' +
            (openFrom.getMinutes() < 10 ? '0' : '') +
            openFrom.getMinutes();
        closedAtLabel =
            (closedAt.getHours() < 10 ? '0' : '') +
            closedAt.getHours() +
            '.' +
            (closedAt.getMinutes() < 10 ? '0' : '') +
            closedAt.getMinutes();

        label += venue.openingHourLabels.fromTo.replace('%from%', openFromLabel).replace('%to%', closedAtLabel);

        return label;
    };

    venue.fillEmptyNowOpenItems = function () {
        // Only select empty items, the ones that have been filled earlier will be ignored
        var items = document.querySelectorAll('[' + dataAttribute + ']:empty');

        [].forEach.call(items, function (item) {
            var venueOpeningHours = openingHoursPerVenue[item.getAttribute(dataAttribute)];

            if (!venueOpeningHours) {
                // No openingHours for this venue, remove the node and return
                item.parentNode.removeChild(item);
                return;
            }

            if (isVenueOpen(venueOpeningHours, 'now')) {
                item.innerHTML = getLabel(venueOpeningHours[dates.today], venue.openingHourLabels.nowOpen, true);
            } else if (isVenueOpen(venueOpeningHours, 'today')) {
                item.innerHTML = getLabel(venueOpeningHours[dates.today], venue.openingHourLabels.todayOpen, false);
            } else if (isVenueOpen(venueOpeningHours, 'tomorrow')) {
                item.innerHTML = getLabel(
                    venueOpeningHours[dates.tomorrow],
                    venue.openingHourLabels.tomorrowOpen,
                    false,
                );
            } else if (isVenueOpen(venueOpeningHours, 'dayAfterTomorrow')) {
                item.innerHTML = getLabel(
                    venueOpeningHours[dates.dayAfterTomorrow],
                    venue.openingHourLabels.dayAfterTomorrowOpen,
                    false,
                );
            } else {
                // OK venue won't be open in the next days, so remove the node
                item.parentNode.removeChild(item);
            }
        });
    };

    venue.fillEmptyNowOpenItems();

    jQuery(document).on('MaxServ.Places.Search.Rendered', function () {
        venue.fillEmptyNowOpenItems();
    });
});
