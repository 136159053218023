/*global window, SlickSliderOptions, document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    if (window.SlickSliderOptions !== undefined) {
        jQuery.each(SlickSliderOptions, function (key, configuration) {
            if (configuration !== null && configuration.default !== undefined && configuration.navigation !== undefined) {
                var mainSlider = $('.slick_slider_' + key),
                    navSlider = $('.slick_slider_nav_' + key);

                navSlider.find('.slick-slide').not('.slick-cloned').first().addClass('slickSlider-activeNavSlide');

                /*jslint unparam: true*/
                mainSlider.slickSetOption('onAfterChange', function (slickElement, index) {
                    navSlider.find('.slick-slide.slickSlider-activeNavSlide').removeClass('slickSlider-activeNavSlide');
                    navSlider.find('[index=\'' + index + '\']').addClass('slickSlider-activeNavSlide');
                });
                /*jslint unparam: false*/
            }
        });
    }
});
