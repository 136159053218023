/*global document, window, jQuery, SlickSliderIdentifiers, SlickSliderOptions*/
jQuery(document).ready(function ($) {
    'use strict';

    if (window.SlickSliderOptions !== undefined) {
        jQuery.each(SlickSliderOptions, function (key, configuration) {
            if (configuration !== null && configuration.default !== undefined && configuration.navigation !== undefined) {
                var defaultConfiguration = configuration.default,
                    navigationConfiguration = configuration.navigation;

                // Check if there is a additional slider to be used as a navigation
                if($('.slick_slider_nav_' + key).length) {
                    // map the navigation slider to the main one
                    defaultConfiguration.asNavFor = '.slick_slider_nav_' + key;
                    navigationConfiguration.asNavFor = '.slick_slider_' + key;

                    // this option must be set for the navigation slider to react upon selection of a slide
                    navigationConfiguration.focusOnSelect = true;
                    navigationConfiguration.centerMode = true;

                    $('.slick_slider_' + key).slick(defaultConfiguration);
                    $('.slick_slider_nav_' + key).slick(navigationConfiguration);
                } else {
                    $('.slick_slider_' + key).slick(defaultConfiguration);
                }
            }
        });
    }
});