/*global window, document, jQuery, Foundation, Modernizr*/

// README
// MaxScrollHeader.js assumes that an element with the classname 'maxScrollHeader' already exists on the page
// Please make sure that you've created this element (e.g. by cloning your header) and adding the maxScrollHeader class
// This script fires two events, which you can bind with this code:
//   $(document).on('MaxServ.ScrollHeader.MenuHidden', function () { /* Your code here */ });
//   $(document).on('MaxServ.ScrollHeader.MenuVisible', function () { /* Your code here */ });

// Especially the first event can be useful to hide submenu's when the maxScrollHeader gets hidden

jQuery(document).ready(function ($) {
    'use strict';

    var $maxScrollHeader = $('.maxScrollHeader'),
        startYposition = -1,
        lastYposition = -1,
        maxScrollHeaderHeight = -1,
        maxScrollHeaderScrollOffset = 1,
        addTransition,
        transformSpeed,
        previousScrollDirection,
        maxScrollHeaderIsHidden = true,
        currentTopOffset = $(document).scrollTop(),
        ticking = false;

    addTransition = function () {
        $maxScrollHeader.addClass('maxScrollHeader-transformSmooth');

        window.setTimeout(function () {
            $maxScrollHeader.removeClass('maxScrollHeader-transformSmooth');
        }, transformSpeed);
    };

    maxScrollHeaderHeight = $maxScrollHeader.outerHeight();

    $maxScrollHeader.addClass('maxScrollHeader-transformSmooth');
    transformSpeed = $maxScrollHeader.css('transition');
    if (transformSpeed !== undefined && transformSpeed !== null && transformSpeed !== '') {
        transformSpeed = parseFloat(transformSpeed.match(/(\d+\.?\d*)s/)[1]) * 1000;
    }
    $maxScrollHeader.removeClass('maxScrollHeader-transformSmooth');

    function onScroll() {
        var maxScrollHeaderOffset,
            requiresTransition = false;

        if (startYposition === -1) {
            startYposition = currentTopOffset;
            lastYposition = startYposition;
            maxScrollHeaderScrollOffset = (maxScrollHeaderHeight * -1);
        }

        if (lastYposition < currentTopOffset) {
            // We're scrolling down

            if (startYposition > lastYposition) {
                startYposition = lastYposition;
            }

            if (previousScrollDirection === 'up' || previousScrollDirection === '' || previousScrollDirection === undefined) {
                startYposition += maxScrollHeaderScrollOffset;
            }

            if (maxScrollHeaderScrollOffset > (maxScrollHeaderHeight * -2)) {
                maxScrollHeaderOffset = (startYposition - currentTopOffset);

                if (lastYposition - currentTopOffset < -50) {
                    maxScrollHeaderOffset = maxScrollHeaderHeight * -1;
                    requiresTransition = true;
                }

                if (maxScrollHeaderOffset < (maxScrollHeaderHeight * -1)) {
                    maxScrollHeaderOffset = maxScrollHeaderHeight * -1;
                }

                if (maxScrollHeaderOffset !== maxScrollHeaderScrollOffset) {
                    if (requiresTransition === true) {
                        addTransition();
                    }

                    window.setTransformTranslate($maxScrollHeader[0], 0, maxScrollHeaderOffset + 'px');
                    maxScrollHeaderScrollOffset = maxScrollHeaderOffset;
                }
            }

            previousScrollDirection = 'down';
        } else if (lastYposition > currentTopOffset) {
            // We're scrolling up

            if (startYposition < lastYposition) {
                startYposition = lastYposition;
            }

            if (previousScrollDirection === 'down' || previousScrollDirection === '' || previousScrollDirection === undefined) {
                startYposition += (maxScrollHeaderScrollOffset + maxScrollHeaderHeight);
            }

            if (currentTopOffset < (maxScrollHeaderHeight * 2)) {
                addTransition();
                window.setTransformTranslate($maxScrollHeader[0], 0, maxScrollHeaderHeight * -1 + 'px');
                maxScrollHeaderScrollOffset = maxScrollHeaderHeight * -1;
            } else if (maxScrollHeaderScrollOffset < 0) {
                maxScrollHeaderOffset = (startYposition - currentTopOffset);

                if ((lastYposition - currentTopOffset) > 50) {
                    maxScrollHeaderOffset = maxScrollHeaderHeight;
                    addTransition();
                }

                if (maxScrollHeaderOffset > maxScrollHeaderHeight) {
                    maxScrollHeaderOffset = maxScrollHeaderHeight;
                }

                window.setTransformTranslate($maxScrollHeader[0], 0, (maxScrollHeaderHeight - maxScrollHeaderOffset) * -1 + 'px');
                maxScrollHeaderScrollOffset = (maxScrollHeaderHeight - maxScrollHeaderOffset) * -1;
            }

            previousScrollDirection = 'up';
        }

        if (maxScrollHeaderHeight * -1 === maxScrollHeaderScrollOffset) {
            if (maxScrollHeaderIsHidden === false) {
                $(document).trigger('MaxServ.ScrollHeader.MenuHidden');
            }

            maxScrollHeaderIsHidden = true;
        } else {
            if (maxScrollHeaderIsHidden === true) {
                $(document).trigger('MaxServ.ScrollHeader.MenuVisible');
            }

            maxScrollHeaderIsHidden = false;
        }

        lastYposition = currentTopOffset;
        ticking = false;
    }

    $(window).on('resize.MaxServ.ScrollHeader', Foundation.util.throttle(function () {
        maxScrollHeaderHeight = $maxScrollHeader.height();
        currentTopOffset = $(document).scrollTop();
        window.requestAnimationFrame(onScroll);
    }, 500));

    window.addEventListener('scroll', function () {
        if (ticking === false) {
            currentTopOffset = $(document).scrollTop();
            window.requestAnimationFrame(onScroll);
            ticking = true;
        }
    });

    window.requestAnimationFrame(onScroll);
});
