/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var filterTitle = $('.filterDropdown-title');

    filterTitle.on('click',function (e) {
        e.preventDefault();
        e.stopPropagation();
        var dropDown = $(this);
        dropDown.toggleClass('filterDropdown-title--active');
        dropDown.next('ul').toggleClass('filterDropdown-active');

        if (dropDown.hasClass('filterDropdown-title--kitchenTitle') && dropDown.hasClass('filterDropdown-title--active')) {
            dropDown.text('Filter inklappen');
        } else if (dropDown.hasClass('filterDropdown-title--kitchenTitle')) {
            dropDown.text('Keukens filteren');
        }
    });

    $('.multiple-filter .has-subs li').each(function () {
        if ($(this).hasClass('is-active')) {
            $(this).parents('.filterDropdown-title').addClass('filterDropdown-title--active');
            $(this).parent().addClass('filterDropdown-active');
        }
    });
});
