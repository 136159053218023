(function() {
    var elOne = document.getElementsByClassName('glide--one'); // main slider
    var elTwo = document.getElementsByClassName('glide--two'); // thumbnail slider
    var imgLink = document.querySelector('.ProductHeaderImage-imageLink');
    var glideOne = '.glide--one';
    var glideTwo = '.glide--two';
    var mfpOpen = true; // Variable to set a default value TRUE to open the modal
    var options = {
        type: 'carousel',
        startAt: 0,
        perView: 3,
        perTouch: 1
    };

    // check if slider exist then initialize
    if (elOne.length) {
        var slide1 = new Glide(glideOne);
        slide1.mount();

        $('body').on('click', '.image-link',function(e) {
            e.preventDefault();
            var currentSlide = $('.image-link').attr('data-slide');

            $(glideOne).find('.Placeholder-image')
                .magnificPopup('open', currentSlide); // open gallery with the right image
        });

        // initiate gallery for the slides
        $(glideOne).find('.Placeholder-image')
            .magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                },
                disableOn: function () {
                    return mfpOpen; // Default is TRUE, when slider is running is will be false
                },
                modal: false
            });
    }

    // check if slider 2 exist then initialize and sync with slider 1
    if (elTwo.length) {
        var slide2 = new Glide(glideTwo, options); // slide2 with options
        slide2.mount();

        // sync slide1 with slide2
        RunSlide(slide1);

        // sync slide2 with slide1
        RunSlide(slide2);
        ClickSlides();
    }

    function RunSlide(slide) {
        if (slide.selector === glideOne) {
            slide.on('run', function(e) {
                // When slide is running set mfpOpen to false so the modal can't open
                mfpOpen = false;

                // change data-index so magnific can open the right photo
                imgLink.setAttribute('data-slide', slide.index);

                // check if steps is a number and direction is a '=' sign
                if (typeof(e.steps) === 'number' && e.direction === '=') {
                    slide2.go(e.direction + e.steps);
                } else {
                    var pipe = (e.direction === '|');
                    var go = (pipe && e.steps === '>') ?
                        '>' :
                        ((pipe && e.steps === '<') ?
                            '<' :
                            e.direction);

                    slide2.go(go);
                }
            });

            // When slider move is finished then set mfpOpen to true
            slide.on('move.after', function() {
                mfpOpen = true;
            });
        } else if (slide.selector === glideTwo) {
            slide.on('run', function(e) {
                slide1.go(e.steps);
            })
        }
    }

    function ClickSlides() {
        document.querySelector(glideTwo + ' .glide__slides').addEventListener('click', function(e) {
            var ind = parseInt(e.target.closest('li').getAttribute('data-index'));
            if (ind === 0 || ind > 0) {
                slide1.go('=' + ind)
            }
        });
    }


})();
