/*global document,jQuery,FlipClock*/
jQuery(document).ready(function ($) {
    'use strict';

    var dateCountContainer = $('.js-countDown-list'),
        timestamp = dateCountContainer.data('time'),
        timeDate = new Date(timestamp * 1000);

    if (dateCountContainer.length > 0) {
        dateCountContainer.countdown({
            date: timeDate,
            offset: 2,
            day: 'dag',
            days: 'dagen',
            hour: 'uur',
            hours: 'uur',
            minute: 'minuut',
            minutes: 'minuten',
            second: 'seconde',
            seconds: 'seconden'
        });
    }
});
