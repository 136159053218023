/*global document*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    var submitElements = document.querySelectorAll('.js-submit-on-change');

    if (submitElements) {
        for (var i = 0; i < submitElements.length; i++) {
            var watchElements = submitElements[i].getElementsByClassName('js-submit-on-change-watch');


            for (var j = 0; j < watchElements.length; j++) {
                var formToSubmit = submitElements[i];
                watchElements[j].addEventListener('change', function (e) {
                    formToSubmit.submit();
                })
            }
        }
    }
});
