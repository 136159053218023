/*global document*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    var copyButtons = document.querySelectorAll('.js-copy-to-clipboard');

    for (var i = 0; i < copyButtons.length; i++) {
        copyButtons[i].addEventListener('click', function (e) {
            e.preventDefault();

            var valueToCopy = this.getAttribute('data-copy-to-clipboard');
            if (!valueToCopy.length) {
                valueToCopy = window.location.href;
            }
            navigator.clipboard.writeText(valueToCopy);

            var initialEls = this.querySelectorAll('.js-copy-to-clipboard-success-initial');
            var successEls = this.querySelectorAll('.js-copy-to-clipboard-success');

            for (var j = 0; j < initialEls.length; j++) {
                initialEls[j].classList.add('copy-to-clipboard-success-initial--inactive');
            }

            for (var j = 0; j < successEls.length; j++) {
                successEls[j].classList.add('copy-to-clipboard-success--active');
            }

            setTimeout(function () {
                for (var j = 0; j < initialEls.length; j++) {
                    initialEls[j].classList.remove('copy-to-clipboard-success-initial--inactive');
                }

                for (var j = 0; j < successEls.length; j++) {
                    successEls[j].classList.remove('copy-to-clipboard-success--active');
                }
            }, 2000);
        })
    }
});
