/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var faq = $('.Faq'),
        faqContent = $('.Faq-content'),
        faqGroup = $('.Faq-grouped'),
        faqGroupBtn = $('.Faq-groupedToggleBtn'),
        faqSideBar = $('.Faq-sidebar'),
        faqSideBarBtn = $('.Faq-sidebarBtn'),
        faqItem = $('.Faq-item'),
        faqItemQuestion = $('.Faq-itemQuestion');

    $(faq).each(function () {
        // Set active states
        $(this).find(faqContent).find(faqGroup).first().addClass('is-active');
        $(this).find(faqSideBar).find(faqSideBarBtn).first().addClass('is-active');
    });

    faqGroupBtn.on('click',function () {
        // Toggle a group
        var currentGroup = $(this).parents('.Faq-grouped'),
            relatedSidebarBtn = $(this).attr('data-faq-group');

        currentGroup.addClass('is-active');
        currentGroup.parents('.Faq-content').find(faqGroup).not(currentGroup).removeClass('is-active');

        $(this).parents('.Faq').find(faqSideBar).find(faqSideBarBtn).removeClass('is-active');
        $(this).parents('.Faq').find(faqSideBar).find("[data-faq-group='" + relatedSidebarBtn + "']").addClass('is-active');
    });

    faqSideBarBtn.on('click',function () {
        // Toggle a group with the sidebar buttons (visible on large up)
        var relatedGroup = $(this).attr('data-faq-group');

        $(this).parents('.Faq').find(faqContent).find("[data-faq-group='" + relatedGroup + "']").trigger('click');
    });

    faqItemQuestion.on('click',function () {
        // Toggle items inside a group
        var currentQuestion = $(this).parents('.Faq-item');

        currentQuestion.toggleClass('is-active');
        currentQuestion.siblings(faqItem).removeClass('is-active');
    });
});
