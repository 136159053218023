/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    var sliders = document.querySelectorAll(".js-glide-simple");
    if (sliders.length) {
        for (var i = 0; i < sliders.length; i++) {
            var glide = new Glide(sliders[i], {
                type: "carousel",
                gap: 0,
                startAt: 0,
                keyboard: false,
                swipeThreshold: false,
                dragThreshold: false,
                breakpoints: {
                    768: {
                        // small and medium screen
                        keyboard: true,
                        swipeThreshold: true,
                        dragThreshold: true
                    }
                }
            });
            glide.mount();
        }

        $(window).on('resize', Foundation.util.throttle(function () {
            if (Foundation.MediaQuery.atLeast('large')) {
                glide.update({startAt: 0}).disable();
            } else {
                glide.enable();
            }
        }, 500));
    }
});
