/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    const segments = {
        order: /\/order-[\w\d-]*\/?/,
        sortBy: /\/sort-by-[\w\d-]*\/?/,
        amount: /\/amount-[\w\d-]*\/?/
    }

    $('.js-blog-sorting-form').change((event) => {
        event.preventDefault()

        let $this = $(event.currentTarget)
        let fields = $this.serializeArray()

        let url = new URL(document.URL)
        jQuery.each(segments, function(segment, regex) {
            url.pathname = url.pathname.replace(regex, '/')
        })

        let path = url.pathname + 'order-{order}/sort-by-{sort}/amount-{amount}'
        jQuery.each(fields, function(i, field) {
            let name = field.name.substring(
                field.name.indexOf("[") + 1,
                field.name.lastIndexOf("]")
            );

            switch(name) {
                case 'order':
                    path = path.replace('{order}', field.value).toLowerCase()
                    break;
                case 'sort_by':
                    path = path.replace('{sort}', field.value)
                    break;
                case 'amount':
                    path = path.replace('{amount}', field.value)
                    break;
            }
        })

        url.pathname = path
        window.location.replace(url.toString())
    })
});
