/*global window,document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var $telInputElements = $('input[type="tel"]');

    if($telInputElements.length === 0) {
        $telInputElements = $('input[parsley-type="tel"]');
    }

    window.Parsley.addValidator('phone', {
        // Add a custom parsley validator.
        validateString: function (value, required, parsleyInstance) {
            if (window.intlTelInputSettings.separateDialCode) {
                // Because we use the intlTelInput option `separateDialCode`, we need to overwrite the validator.
                // By default it will validate by checking if it's numeric.
                // But with the separateDialCode option it will add the
                // country code on blur. For example `+3112345678`.
                if (value.startsWith('+')) {
                    value = value.replace(/^\+/g, '');
                }
                return !isNaN(value);
            } else {
                return parsleyInstance.$element.intlTelInput('isValidNumber');
            }
        }
    });

    if ($telInputElements.length > 0 && typeof window.intlTelInputSettings === 'object') {
        $.getScript(window.intlTelInputSettings.jsFile, function () {

            var countryData = $.fn.intlTelInput.getCountryData();
            $.each(countryData, function (i, country) {
                country.name = country.name.replace(/.+\((.+)\)/, "$1");

                if (country.iso2 === 'be') {
                    // Add Belgium's French name after the Dutch name
                    country.name += ' (Belgique)';
                }
            });

            // These variables can be set by TypoScript constants (plugin.tx_powermailextras.settings.telAutocompletion)
            $telInputElements.intlTelInput({
                initialCountry: window.intlTelInputSettings.initialCountry,
                onlyCountries: window.intlTelInputSettings.onlyCountries,
                preferredCountries: [],
                separateDialCode: window.intlTelInputSettings.separateDialCode
            });

            $telInputElements.each(function () {
                var $telField = $(this);
                $telField.attr('data-parsley-phone', '');
            });

            $telInputElements.on('blur', function () {
                if ($(this).intlTelInput('isValidNumber') === true) {
                    // User entered a valid phone number, replace the phone number with the international number
                    $(this).val($(this).intlTelInput('getNumber'));
                }
                if (window.intlTelInputSettings.separateDialCode) {
                    $(this).parsley().validate();
                }
            });
        });
    }
});