/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    var menuToggle = $('.PageFooter-main .frame-type-menu_pages header, .PageFooter-main .frame-type-menu_subpages header');
    var venueToggle = $('.province .province-name');

    menuToggle.on('click',function () {
        if (Foundation.MediaQuery.atLeast('large') === false) {
            menuToggle.not($(this)).removeClass('is-open');
            $(this).toggleClass('is-open');
        }
    });

    venueToggle.on('click',function () {
        if (Foundation.MediaQuery.atLeast('large') === false) {
            venueToggle.not($(this)).removeClass('is-open');
            $(this).toggleClass('is-open');
        }
    });
});
