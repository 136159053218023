/*global document, jQuery, tx_lookup_results_ajaxTypeNum, tx_lookup_results_more_label */
/*jslint unparam: true, regexp: true*/
(function ($) {
    'use strict';

    var ResultBrowser,
        instances = [];

    ResultBrowser = function ($container) {
        this.$container = $container;
        this.pageNumber = 0;
        this.id = $container.attr('id');
    };

    ResultBrowser.prototype.initialize = function (documentUrl) {
        var id = this.id.substr(1),
            match = documentUrl.match(new RegExp('page-' + id + '[^=]+=(\\d+)'));

        if (match !== undefined && match !== null && match[1] !== undefined && match[1] !== null) {
            this.pageNumber = parseInt(match[1], 10);
        }
    };

    ResultBrowser.prototype.getCurrentPage = function () {
        return this.pageNumber;
    };

    ResultBrowser.prototype.getNextPage = function ($block) {
        var id = this.id.substr(1),
            pageNumbers = [],
            self = this;

        if ($block === undefined) {
            $block = this.$container;
        }

        $block.find('a[href*=page-' + id + ']').each(function () {
            var $anchor = $(this),
                hrefAttribute = $anchor.attr('href'),
                match = hrefAttribute.match(new RegExp('page-' + id + '[^=]+=(\\d+)'));

            if (match !== undefined && match !== null && match[1] !== undefined && match[1] !== null) {
                pageNumbers.push(parseInt(match[1], 10));
            }
        });

        pageNumbers = pageNumbers.filter(function (pageNumber) {
            return pageNumber > self.pageNumber;
        }).sort();

        return pageNumbers.length > 0 ? pageNumbers[0] : null;
    };

    ResultBrowser.prototype.bindEvents = function () {
        var self = this;

        this.$container.find('[data-lookup-next-page]').on('click', function () {
            self.goToPage(self.getCurrentPage() + 1);
        });

        this.$container.on('lookupSetApplicationState', function (event, applicationState) {
            if (applicationState !== null && applicationState.page !== undefined && applicationState.page !== null) {
                self.goToPage(applicationState.page);
            }
        });
    };

    ResultBrowser.prototype.goToPage = function (targetPageNumber) {
        var data = {},
            id = this.id.substr(1),
            self = this,
            url = document.location.href;

        data.type = tx_lookup_results_ajaxTypeNum;
        data.lookup = {
            instance: id
        };
        data.lookup['from-' + id] = this.pageNumber + 1;
        data.lookup['page-' + id] = targetPageNumber;

        url = url.replace(/&cHash[^=]*=[a-z0-9]{32}/, '');

        $.ajax({
            url: url,
            data: data,
            success: function (doc) {
                var $block = $($(doc).find('block[id="' + id + '"]').text());

                self.appendResults($block);

                self.prepareForNextPage($block, targetPageNumber);
            }
        });
    };


    ResultBrowser.prototype.prepareForNextPage = function ($block, targetPageNumber) {
        var applicationState = {};

        this.pageNumber = targetPageNumber;

        if (this.getNextPage($block) === null) {
            this.$container.find('[data-lookup-next-page]').remove();
        }

        applicationState.id = this.id.substr(1);
        applicationState.page = this.pageNumber;

        this.$container.trigger('lookupApplicationStateChange', [applicationState]);
    };

    ResultBrowser.prototype.appendResults = function ($block) {
        this.$container.find('[data-lookup-results]').append($block.find('[data-lookup-results]>*'));
    };

    $(function () {
        $('[data-lookup-results]').each(function () {
            var $results = $(this),
                $container = null,
                instance = null,
                buttonLabel = '+';

            if (tx_lookup_results_more_label !== undefined && tx_lookup_results_more_label !== null) {
                buttonLabel = tx_lookup_results_more_label;
            }

            if ($results.data('lookup-results-more-label')) {
                buttonLabel = $results.data('lookup-results-more-label');
            }

            if ($results.closest('[id^="c"]').length === 0) {
                $container = $results.parent();
            } else {
                $container = $results.closest('[id^="c"]');
            }

            if ($container !== null && $container.length === 1) {
                instance = new ResultBrowser($container);

                instance.initialize(document.location.href);

                if (instance.getNextPage()) {
                    $container.find('[data-lookup-page-browser]').html(
                        '<button data-lookup-next-page class="loadAdditionalResults">' + buttonLabel + '</button>'
                    );
                }

                instance.bindEvents();

                instances.push(instance);
            }
        });
    });
}(jQuery));
