/*global document, jQuery */
jQuery(document).ready(function ($) {
    'use strict';

    var mySlider = $('.HotspotSlider'),
        spot = $('.HotSpot-spot');

    mySlider.slick({
        arrows: false,
        variableWidth: true,
        centerMode: true,
        speed: 200,
        touchThreshold: 3
    });

    mySlider.slickSetOption('onAfterChange', function (slickElement, index) {
        var currentSlider = slickElement.$slider,
            currentHotSpot = currentSlider.parents('.HotSpots').find('[data-spot-number=' + index + ']');

        // Toggle active class on related hot spot
        currentSlider.parents('.HotSpots').find('[data-spot-number]').not(currentHotSpot).removeClass('is-active');

        if (currentHotSpot.hasClass('js-closed')) {
            currentHotSpot.removeClass('is-active js-closed');
        } else {
            currentHotSpot.addClass('is-active');
        }
    });

    spot.on('click',function (e) {
        if ($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
        } else {
            // Slide to correct current slide on mobile viewport:
            $(this).parents('.HotSpots').find(mySlider).slickGoTo($(this).attr('data-spot-number'));
        }
    });

    $(document).on('click',function () {
        if (mySlider.length && !$(event.target).closest(spot).length) {
            spot.removeClass('is-active');
        }
    });
});
