/*global jQuery*/
/*jslint regexp: true, unparam: true*/
(function () {
    'use strict';

    // extend the behaviour of magnific popup, allow embedding of youtu.be videos
    if (jQuery.magnificPopup !== undefined && jQuery.magnificPopup !== null
            && jQuery.magnificPopup.defaults !== undefined && jQuery.magnificPopup.defaults !== null
            && jQuery.magnificPopup.defaults.iframe !== undefined && jQuery.magnificPopup.defaults.iframe !== null
            && jQuery.magnificPopup.defaults.iframe.patterns !== undefined && jQuery.magnificPopup.defaults.iframe.patterns !== null
            && jQuery.magnificPopup.defaults.iframe.patterns.youtube !== undefined && jQuery.magnificPopup.defaults.iframe.patterns.youtube !== null
            ) {
        jQuery.magnificPopup.defaults.iframe.patterns.ytbe = {};
        jQuery.magnificPopup.defaults.iframe.patterns.ytbe.src = jQuery.magnificPopup.defaults.iframe.patterns.youtube.src;
        jQuery.magnificPopup.defaults.iframe.patterns.ytbe.index = 'youtu.be';
        jQuery.magnificPopup.defaults.iframe.patterns.ytbe.id = function (url) {
            var videoId = url.match(/youtu\.be\/([^\?]+)/);

            return videoId[1];
        };
    }
}());

jQuery(function ($) {
    'use strict';

    var $links = $('a[href*="vimeo.com"], a[href*="youtu.be"], a[href*="youtube.com/watch?"]').filter(function (index, element) {
        // make sure links to vimeo start with digits as first segment otherwise it isn't a video
        return $(element).attr('href').search(/vimeo\.com\/\d+|youtube\.com\/watch\?|youtu\.be/) !== -1;
    });

    $links.addClass(function () {
        var classNames = ['is-video-link'];

        if ($(this).find('img').length) {
            classNames.push('is-video-still');
        }

        return classNames.join(' ');
    });

    $links.not('[data-magnific-skip]').magnificPopup({
        type: 'iframe'
    });
});