document.addEventListener('DOMContentLoaded', function () {
    var url = new URL(window.location),
        params = new URLSearchParams(url.search),
        kitchenTitle = params.get('kitchentitle'),
        cageNumber = params.get('cagenumber'),
        productCode = params.get('productcode');

    window.communited = typeof (window.communited) == 'undefined' ? {} : window.communited;
    window.communited.timeblockr = window.communited.timeblockr || {};
    window.communited.timeblockr.controldata = window.communited.timeblockr.controldata || {}; // set field values prefill
    window.communited.timeblockr.controldata.savedDynamicFields = window.communited.timeblockr.controldata.savedDynamicFields || [];

    if (kitchenTitle && kitchenTitle.length > 0) {
        window.communited.timeblockr.controldata.savedDynamicFields.push(getTimeblockrFieldData('KITCHENTITLE', kitchenTitle));
    }
    if (cageNumber && cageNumber.length > 0) {
        window.communited.timeblockr.controldata.savedDynamicFields.push(getTimeblockrFieldData('CAGENUMBER', cageNumber));
    }
    if (productCode && productCode.length > 0) {
        window.communited.timeblockr.controldata.savedDynamicFields.push(getTimeblockrFieldData('PRODUCTCODE', productCode));
    }

    function getTimeblockrFieldData(code, value) {
        return {
            DynamicFieldSourceType: 'UserProfileField',
            Code: code,
            Value: value
        };
    }
});
