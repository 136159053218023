document.addEventListener('alpine:init', () => {
    Alpine.store('mobileMenuGlobals', {
        menuOpen: false,
        /**
         * @var {Element|null}
         */
        lastMainLink: null,
    });
    Alpine.data('mobileMenu', (bodyOpenClasses = 'is-open--mobileMenu', subMenuOpenClasses = 'mobileMenu-subMenu--open', subMenuClosedClasses = '') => ({
        bodyOpenClasses: '',
        subMenuOpenClasses: '',
        subMenuClosedClasses: '',
        subMenuOpen: false,
        prevLocation: '',
        /**
         * @var {Element|null}
         */
        prevSubMenu: null,
        /**
         * @var {Element|null}
         */
        currentSubMenu: null,
        /**
         * @var {Element|null}
         */
        searchField: null,
        /**
         * @var {Element|null}
         */
        toggleButton: null,
        /**
         * @var {Element|null}
         */
        backButton: null,
        /**
         * @var {Element|null}
         */
        firstHighlightItem: null,
        /**
         * @var {Element|null}
         */
        firstSubMenuLink: 'js-mobileMenu-subMenuLink--first',
        lastSubMenuLink: 'js-mobileMenu-subMenuLink--last',
        init() {
            if (document.querySelector('.js-mobileMenuSearch')) {
                this.searchField = document.querySelector('.js-mobileMenuSearch');
            }
            this.firstHighlightItem = document.querySelector('.js-mobileFirstHighlightItem');
            this.backButton = document.querySelector('.js-mobileBackButton');
            this.toggleButton = document.querySelector('.js-mobileMenuToggle');
            this.bodyOpenClasses = bodyOpenClasses.split(' ');
            this.subMenuOpenClasses = subMenuOpenClasses.split(' ');
            this.subMenuClosedClasses = subMenuClosedClasses.split(' ');
        },
        openMenu(event) {
            Alpine.store('mobileMenuGlobals').menuOpen = event.menuState;
            this.prevLocation = event.clickedElement;

            if (Alpine.store('mobileMenuGlobals').menuOpen) {
                this.$nextTick(() => {
                    if (this.prevLocation.includes('js-mobileMenuToggle')) {
                        if (this.currentSubMenu) {
                            this.currentSubMenu.querySelector(':scope > li:first-child a').focus();
                        } else {
                            this.firstHighlightItem.focus();
                        }
                    } else if (this.searchField) {
                        this.searchField.focus();
                    }
                });
            }
        },
        openSubMenu(element) {
            if(this.currentSubMenu) {
                this.prevSubMenu =  this.currentSubMenu;
            }

            this.currentSubMenu = element.nextElementSibling;
            this.subMenuOpen = true;

            //Set focus on the first link of the current submenu
            this.currentSubMenu.classList.add(...this.subMenuOpenClasses);
            if (this.subMenuClosedClasses[0]) {
                this.currentSubMenu.classList.remove(...this.subMenuClosedClasses);
            }

            this.currentSubMenu.querySelector('a')?.focus();

            document.body.classList.add(...this.subMenuOpenClasses);
        },
        closeSubMenu() {
            //Set focus on the anchor tag which was used to enter the submenu
            this.currentSubMenu.classList.remove(...this.subMenuOpenClasses);
            if (this.subMenuClosedClasses[0]) {
                this.currentSubMenu.classList.add(...this.subMenuClosedClasses);
            }

            this.currentSubMenu.previousElementSibling.focus();

            if (this.prevSubMenu) {
                this.currentSubMenu = this.prevSubMenu;
                this.prevSubMenu = null;
                return;
            }

            this.currentSubMenu = null;
            this.subMenuOpen = false;

            document.body.classList.remove(...this.subMenuOpenClasses);
        },
        //The following function are related to the tab behavior of the menu
        goToPreviousElement(element) {
            if (this.subMenuOpen) {
                if (this.searchField && element !== this.searchField) {
                    this.searchField.focus();
                } else if (element === this.searchField) {
                    this.backButton.focus();
                } else if (element === this.firstHighlightItem) {
                    this.backButton.focus();
                }
            } else {
                if (element === this.searchField) {
                    this.toggleButton.focus();
                } else {
                    Alpine.store('mobileMenuGlobals').lastMainLink.focus();
                }
            }
        },
        //Set focus on the search field
        goToTopOfMenu(event) {
            if (!event.shiftKey) {
                event.preventDefault();

                if (this.searchField) {
                    this.searchField.focus();
                } else {
                    this.firstHighlightItem.focus();
                }
            }
        },
        //Set the focus on the first submenu item
        goToFirstSubMenuItem(event) {
            if (!event.shiftKey && this.subMenuOpen) {
                event.preventDefault();
                this.currentSubMenu.querySelector('a').focus();
            }
        },
        //Loop back to the last submenu item
        goToLastSubMenuItem() {
            this.currentSubMenu.querySelector(':scope > li:last-child .js-mobileMenu-subMenuLink--last')?.focus();
        },
        //Jump out of the submenu and go to the top items
        goToTopMenuItems(element, event) {
            if (element.classList.contains(this.lastSubMenuLink)) {
                if (!event.shiftKey) {
                    event.preventDefault();
                    this.backButton.focus();
                }
            } else if (element.classList.contains(this.firstSubMenuLink)) {
                event.preventDefault();
                this.backButton.focus();
            }
        }
    }));
    Alpine.data('mobileHeader', (bodyOpenClasses = 'is-open--mobileMenu') => ({
        bodyOpenClasses: '',

        init() {
            this.bodyOpenClasses = bodyOpenClasses.split(' ');
            Alpine.store('mobileMenuGlobals').lastMainLink = document.querySelector('.js-mobileMenu-mainLink--last');
        },
        openMenu(element, parent) {
            const prevLocation = `.${parent.replace(/\s/g, ".")} .${element.replace(/\s/g, '.')}`;
            this.$dispatch('toggle-menu', {menuState: true, clickedElement: prevLocation});
            document.body.classList.add(...this.bodyOpenClasses);
        },
        closeMenu(element, parent) {
            Alpine.store('mobileMenuGlobals').menuOpen = false;
            document.body.classList.remove(...this.bodyOpenClasses);
            this.goToLastElement();
        },
        toggleMenu(element, parent) {
            Alpine.store('mobileMenuGlobals').menuOpen = !Alpine.store('mobileMenuGlobals').menuOpen;

            if (Alpine.store('mobileMenuGlobals').menuOpen) {
                this.openMenu(element, parent);
            } else {
                this.closeMenu(element, parent);
            }
        },
        goToLastElement(event) {
            if (Alpine.store('mobileMenuGlobals').menuOpen) {
                event.preventDefault();
                this.$nextTick(() => {
                    Alpine.store('mobileMenuGlobals')?.lastMainLink?.focus();
                });
            }
        },
    }));
});
