/*global document, dutchAddressCompletion, jQuery, window*/
/*jslint plusplus:true, unparam:true*/
jQuery(function ($) {
    'use strict';

    var buildRequestForFieldGroup,
        requestRegistry = {},
        elementCounter = 0,
        throttle;

    /**
     * Delay execution of the given method
     *
     * @param {Function} method
     * @param {Number} delay
     * @returns {Function}
     */
    throttle = function (method, delay) {
        var timer = null;

        return function () {
            var context = this,
                args = arguments;

            if (timer === null) {
                timer = window.setTimeout(function () {
                    method.apply(context, args);
                    timer = null;
                }, delay);
            }
        };
    };

    buildRequestForFieldGroup = function ($fieldGroup) {
        var instanceKey = $fieldGroup.data('dac-instance'),
            request = null,
            requestData = {
                'eID': 'dutch_address_completion'
            };

        // abort request if this field group already has one pending
        if (requestRegistry[instanceKey] !== undefined && requestRegistry[instanceKey] !== null) {
            requestRegistry[instanceKey].abort();
        }

        $fieldGroup.find('[data-dac-role]').each(function () {
            var $field = $(this),
                role = $field.data('dac-role'),
                value = $field.val();

            if (value !== undefined && value !== null && value !== '' && $.trim(value) !== '') {
                requestData[role] = value;
            }
        });

        // make sure the required parameters for the request are set
        if (requestData.postcode !== undefined && requestData.postcode !== null && requestData.houseNumber !== undefined && requestData.houseNumber !== null) {
            var url = dutchAddressCompletion.baseUrl || "/";
            request = $.get(url, requestData);

            requestRegistry[instanceKey] = request;
        }

        return request;
    };

    // prepare configured fields by adding a data attribute that can be used later in this script
    $(document).on('dutchAddressCompletion', function() {
        window.dutchAddressCompletion=window.dutchAddressCompletion||{};
        if (dutchAddressCompletion.fields && typeof dutchAddressCompletion.fields === "object") {
            $.each(dutchAddressCompletion.fields, function (key, value) {
                if (value !== undefined && value !== null && value[0] !== undefined && value[0] !== null && value[1] !== undefined && value[1] !== null) {
                    $(value[0]).attr('data-dac-role', value[1]);
                }
            });
        }
    }).trigger('dutchAddressCompletion');

    $(document).trigger('MaxServ_DutchAddressCompletion_Initialize');

    $(document).on('keyup', '[data-dac-role]', throttle(function () {
        var $field = $(this),
            $parent = $field.parentsUntil('fieldset').parent('fieldset'),
            request;

        if (!$parent.attr('data-dac-instance')) {
            $parent.attr('data-dac-instance', 'dac' +  elementCounter++);
        }

        request = buildRequestForFieldGroup($parent);

        if (request !== null) {
            $(document).trigger('MaxServ_DutchAddressCompletion_BeforeRequest');

            // if a request is triggered add an in-progress indication to the fieldset
            $parent.addClass('dac-in-progress');

            request.always(function () {
                $(document).trigger('MaxServ_DutchAddressCompletion_AfterRequest');

                $parent.removeClass('dac-in-progress');
            });

            request.fail(function () {
                $(document).trigger('MaxServ_DutchAddressCompletion_FailedRequest');

                $parent.find('[data-dac-role]').addClass('dac-has-failed');
            });

            request.done(function (response) {
                $(document).trigger('MaxServ_DutchAddressCompletion_SuccessfulRequest');

                if (response.data !== undefined && response.data !== null && response.data[0] !== undefined && response.data[0] !== null) {
                    $parent.find('[data-dac-role]').removeClass('dac-has-failed');

                    $.each(response.data[0], function (key, value) {
                        var $target;

                        if (value !== undefined && value !== null) {
                            $target = $parent.find('[data-dac-role=' + key + ']');

                            // if value is available in response and a target field exists replace the form value
                            if ($target.length === 1) {
                                $target.val(value);

                                // dispatch a change event for this field to notify possible observers from custom validators
                                $target.trigger('change');
                            }
                        }
                    });
                }
            });
        }
    }, 300));
});