/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.dye-point-toggle').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('dye-point-toggle--close');
        $(this).parents('.dye-point-inner').find('.dye-point-overlays').toggleClass('dye-point-overlays--close');
        $(this).parents('.dye-point').toggleClass('dye-point--close');
    });

    $('.dye-overlay-title').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.dye-point-inner').find('.dye-point-toggle').trigger('click');
    });
});
