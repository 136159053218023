/*global document, jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var alertBox = $('.alertBox');

    alertBox.addClass('alertBox--slideIn');

    $('.alertBox-close').on('click',function (event) {
        event.preventDefault();
        $(this).parents('.alertBox').slideUp();
    });
});
