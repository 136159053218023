/*global document, jQuery, location, window*/
/*jslint unparam: true*/
(function ($) {
    'use strict';

    var historyAvailable = window.history !== undefined && window.history.pushState !== undefined,
        insertOrUpdateSegmentInUrl,
        serializeApplicationState,
        unserializeApplicationState;

    insertOrUpdateSegmentInUrl = function (url, segment) {
        var identifyingPart = null;

        if (segment.search(/lookup\-\d+\-/) !== -1) {
            identifyingPart = segment.match(/lookup\-(\d+)\-/)[0];
        }

        if (url.indexOf('#') === -1) {
            // add the application state after a # to prevent issues with realurl
            url = url + '#';
        }

        if (url.indexOf(identifyingPart) === -1) {
            url = url + '!/' + segment + '/';
        } else {
            url = url.replace(new RegExp('!\/' + identifyingPart + '[^\/]+\/'), '!/' + segment + '/');
        }

        return url;
    };

    serializeApplicationState = function (applicationStateObject) {
        var applicationStateString = null,
            applicationStateStringLineBuffer = ['lookup'];

        if (applicationStateObject.id !== undefined) {
            applicationStateStringLineBuffer.push(applicationStateObject.id);
        }

        if (applicationStateObject.page !== undefined) {
            applicationStateStringLineBuffer.push('page');
            applicationStateStringLineBuffer.push(applicationStateObject.page);
        }

        if (applicationStateStringLineBuffer.length >= 4) {
            applicationStateString = applicationStateStringLineBuffer.join('-');
        }

        return applicationStateString;
    };

    unserializeApplicationState = function (applicationStateString) {
        var applicationStateObject = {
            id: null,
            page: null
        };

        if (applicationStateString.search(/lookup\-\d+\-/) !== -1) {
            applicationStateObject.id = parseInt(applicationStateString.match(/lookup\-(\d+)\-/)[1], 10);
        }

        if (applicationStateString.search(/page\-\d+/) !== -1) {
            applicationStateObject.page = parseInt(applicationStateString.match(/page\-(\d+)/)[1], 10);
        }

        return applicationStateObject;
    };

    $(document).on('lookupApplicationStateChange', function (event, applicationState) {
        if (historyAvailable) {
            window.history.pushState(
                {},
                document.title,
                insertOrUpdateSegmentInUrl(location.href, serializeApplicationState(applicationState))
            );
        }
    });

    $(window).on('popstate', function () {
        var applicationStates = null;

        if (location.href.indexOf('/lookup-') !== -1) {
            applicationStates = location.href.match(new RegExp('/lookup-\\d+-[^/]+/', 'g'));
        }

        if (applicationStates !== null) {
            $.each(applicationStates, function () {
                var applicationState = this,
                    applicationStateObject = unserializeApplicationState(applicationState.slice(1, -1));

                if (applicationStateObject !== null && applicationStateObject.id !== null) {
                    // make sure the document is ready before triggering setState events
                    $(function () {
                        $('[id="c' + applicationStateObject.id + '"]').trigger(
                            'lookupSetApplicationState',
                            [applicationStateObject]
                        );
                    });
                }
            });
        }
    });
}(jQuery));