/*global jQuery, window*/
jQuery(function ($) {
    'use strict';

    var form = $('.tx-powermail');

    if (form.length !== 0) {
        // prevents submitting when pressing enter in an input field
        $('.powermail_field').not('.powermail_textarea').keypress(function(e) {
            if (e.which === 13) {
                e.preventDefault();
            }
        });

        // prevents sending form multiple times when submit button is clicked a lot
        form.find('.powermail_submit').click(function() {
            var self = this;
            setTimeout(function () {
                disableButton(self);
            }, 100);

            setTimeout(function () {
                enableButton(self);
            }, 3000);
        });

        function disableButton(btn) {
            $(btn).prop('disabled', true);
        }

        function enableButton(btn) {
            $(btn).prop('disabled', false);
        }
    }
});