/*global window, Modernizr*/
window.setTransformTranslate = function (element, xValue, yValue, zValue) {
    'use strict';

    if (xValue === undefined) {
        xValue = 0;
    }

    if (yValue === undefined) {
        yValue = 0;
    }

    if (zValue === undefined) {
        zValue = 0;
    }

    element.style.webkitTransform = 'translate3d(' + xValue + ', ' + yValue + ', ' + zValue + ')';
    element.style.transform = 'translate3d(' + xValue + ', ' + yValue + ', ' + zValue + ')';
};

window.requestAnimFrame = (function () {
    'use strict';

    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
}());
