/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var currentDate = parseInt(new Date().getTime() / 1000, 10);

    function numberFormat(num) {
        return num.toFixed(0).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    $('.magazineCounter').each(function () {
        var additionDate = $(this).data('additiondate'),
            $requestsThisYear = $(this).find('.magazineCounter-numberOfRequestsThisYear'),
            requestsThisYearAmount = parseInt($requestsThisYear.data('counteramount'), 10),
            $requestsThisDay = $(this).find('.magazineCounter-numberOfRequestsThisDay'),
            requestsThisDayAmount = parseInt($requestsThisDay.data('counteramount'), 10),
            $magazineStock = $(this).find('.magazineCounter-magazineStock'),
            timesPassed90Minutes = Math.floor((currentDate - additionDate) / 60 / 90);

        $requestsThisYear.text(numberFormat(requestsThisYearAmount + timesPassed90Minutes));

        $requestsThisDay.text(requestsThisDayAmount + Math.floor((new Date().getHours() * 60 + new Date().getMinutes()) / 90));

        var magazineStockAmountBase = parseInt($magazineStock.data('counteramount'), 10),
            magazineStockAmount = magazineStockAmountBase,
            countingStart = parseInt(new Date(2017, 11, 6).getTime() / 1000, 10),
            daysPast = (currentDate - countingStart) / 60 / 60 / 24,
            /* every one and a half our one magazine) */
            totalMagazinesSent = (requestsThisDayAmount + (24 / 1.5)) * daysPast,
            magazinesAvailable = 0,
            hasValidStock = false,
            baseAmount = 1250;

        while (hasValidStock === false) {
            magazinesAvailable = magazineStockAmount - totalMagazinesSent;

            if (magazinesAvailable < baseAmount) {
                magazineStockAmount += magazineStockAmountBase - baseAmount;
            } else {
                hasValidStock = true;
            }
        }

        $magazineStock.text(numberFormat(Math.floor(magazinesAvailable)));
    });
});
