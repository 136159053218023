/*global document, jQuery, window, wNumb*/
jQuery(function ($) {
    'use strict';

    // check if the noUiSlider library is loaded
    if ($.fn !== undefined && $.fn !== null && $.fn.noUiSlider !== undefined && $.fn.noUiSlider !== null) {
        $('.lookup-facets input[type=range]').each(function () {
            var $rangeInput = $(this),
                $parent = $rangeInput.parent(),
                $sliderElement = null,
                min = parseFloat($rangeInput.attr('min')),
                max = parseFloat($rangeInput.attr('max')),
                step = parseFloat($rangeInput.attr('step')),
                value = [parseFloat($rangeInput.val())],
                uri,
                lowerInput,
                upperInput,
                numberFormat;

            if (!($parent.length === 1 && $parent.is('[data-lookup-range-uri]'))) {
                $parent = $rangeInput.parentsUntil('[data-lookup-range-uri]').parent();
            }

            numberFormat = wNumb({
                decimals: parseInt($parent.attr('data-lookup-range-format-decimals') || 0, 10)
            });

            if ($parent.is('[data-lookup-range-value]')) {
                value = $parent.attr('data-lookup-range-value').split(',');

                value = [parseFloat(value[0]), parseFloat(value[1])];
            }

            if ($parent.is('[data-lookup-range-lower]')) {
                lowerInput = $parent.find('#' + $parent.attr('data-lookup-range-lower'));
            }

            if ($parent.is('[data-lookup-range-upper]')) {
                upperInput = $parent.find('#' + $parent.attr('data-lookup-range-upper'));
            }

            $sliderElement = $parent.find('[data-lookup-range-slider]').append('<div id="' + $rangeInput.attr('id') + '-ui" />');

            uri = $parent.attr('data-lookup-range-uri');

            $sliderElement.noUiSlider({
                start: value,
                step: step,
                connect: true,
                serialization: {
                    resolution: 1
                },
                range: {
                    'min': [min],
                    'max': [max]
                }
            });

            if (lowerInput !== undefined && lowerInput !== null && lowerInput.length === 1) {
                $sliderElement.Link('lower').to(lowerInput, null, numberFormat);
            }

            if (upperInput !== undefined && upperInput !== null && upperInput.length === 1) {
                $sliderElement.Link('upper').to(upperInput, null, numberFormat);
            }

            $sliderElement.on('set', function () {
                var values = $sliderElement.val();

                window.location = uri.replace('%23range%23', values[0] + '-' + values[1]);
            });

            $rangeInput.remove();
        });
    }
});
