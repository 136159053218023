/*global $, document, jQuery, cta*/
jQuery(document).ready(function ($) {
    "use strict";

    initializeOtherCtaTargets();
    var sidebar, closeFn, i, sidebardivs = document.querySelectorAll("[data-cta-target]");

    function initializeOtherCtaTargets () {
        var pageButton = $('a.pageButton');

        pageButton.each(function() {
            var pageButtonUrl = $(this).attr('href'),
                pageButtonTarget = pageButtonUrl.substring(pageButtonUrl.lastIndexOf("/") + 1);

            if (pageButtonTarget.indexOf('#js-sidebar') >= 0) {
                pageButtonTarget = pageButtonTarget.replace(/#/g , '.');
                $(this).attr('data-cta-target', pageButtonTarget );
            }
        });

        pageButton.on('click',function(e){
            if ($(this).attr('href').indexOf('#js-sidebar') >= 0) {
                e.preventDefault();
            }
        });
    }

    function closeShowingModal(liked) {
        var showingModal = document.querySelector(".modal.show");
        if (!showingModal) {
            return;
        }
        showingModal.classList.remove("show");
        $(".blur-overlay").remove();
        $("body").removeClass("noScroll");
        if (closeFn) {
            closeFn();
            closeFn = null;
        }
    }

    sidebar = function (e) {
        var target = e.target;
        if (target.dataset.ctaTarget) {
            closeFn = cta(target, $(this).closest('.js-cta-wrapper').find(target.dataset.ctaTarget)[0], {relativeToWindow: true}, function showModal(modal) {
                modal.classList.add("show");
                $("body").addClass("noScroll").prepend("<div class='blur-overlay'></div>");
                $(".blur-overlay").fadeTo(300, 0.6);
                $(".blur-overlay, .sidebar--closebutton ").on("click",function () {
                    closeShowingModal();
                });
            });
        }
    };

    for (i = 0; i < sidebardivs.length; i += 1) {
        sidebardivs[i].addEventListener("click", sidebar);
    }
});
