/*global jQuery, Pikaday, window*/
/*jslint nomen: true*/
// use the init event dispatched by parsley before initializing the datepicker UI
jQuery.listen('parsley:form:init', function (parsleyForm) {
    'use strict';

    function addClasses() {
        jQuery('.pika-prev').addClass('needsclick');
        jQuery('.pika-next').addClass('needsclick');
    }

    parsleyForm.$element.find('[type="date"]').each(function () {
        var $container = jQuery('<div />'),
            defaultValue = new Date(),
            $input = jQuery(this),
            inputId = $input.attr('id'),
            dayLabels = $input.data('datepicker-days'),
            monthLabels = $input.data('datepicker-months'),
            options = {
                container: $container[0],
                field: $input[0],
                onDraw: addClasses,
                firstDay: 1, // First day is monday
                todayButton: true
            },
            picker,
            queryParts;

        $container.insertAfter($input);
        $container.addClass('datepicker-container needsclick');

        // force the input to type=text to disable UA specific rendering of a datepicker
        $input.prop('type', 'text');

        // display the datepicker in a separate container
        if ($input.data('datepicker-force')) {
            options.bound = false;
        }

        if (monthLabels && dayLabels) {
            options.i18n = {
                today : $input.data('datepicker-today'),
                previousMonth : 'Previous Month',
                nextMonth : 'Next Month',
                months: monthLabels.split(','),
                weekdays : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                weekdaysShort: dayLabels.split(',')
            };

            // check if the split character is used, this could indicate optional long labels are set
            if (dayLabels.indexOf('|') !== -1) {
                jQuery.each(dayLabels.split(','), function (index, label) {
                    if (label.indexOf('|') !== -1) {
                        var parts = label.split('|');

                        options.i18n.weekdaysShort[index] = parts[0];
                        options.i18n.weekdays[index] = parts[1];
                    }
                });
            }
        }

        if ($input.data('datepicker-min')) {
            try {
                options.minDate = new Date($input.data('datepicker-min'));
            } catch (ignore) {
                //
            }
        }

        if ($input.data('datepicker-max')) {
            try {
                options.maxDate = new Date($input.data('datepicker-max'));
            } catch (ignore) {
                //
            }
        }

        if ($input.data('datepicker-format')) {
            options.format = $input.data('datepicker-format');
        }

        options.onSelect = function (selectedDate) {
            var formattedDate = selectedDate.toString(),
                formatCharacters = {
                    'd': selectedDate.getDate().toString(),
                    'm': (selectedDate.getMonth() + 1).toString(),
                    'Y': selectedDate.getFullYear().toString()
                };

            // prefix "d" with a leading zero if the character count is just one
            // see http://php.net/manual/en/function.date.php
            if (formatCharacters.d.length === 1) {
                formatCharacters.d = '0' + formatCharacters.d;
            }

            // "m" should also be prefixed with a leading zero
            if (formatCharacters.m.length === 1) {
                formatCharacters.m = '0' + formatCharacters.m;
            }

            window.location.hash = inputId + '=' + selectedDate.getTime();
            if (picker !== undefined && picker !== null && picker._o !== undefined && picker._o !== null && picker._o.format !== undefined) {
                formattedDate = picker._o.format;

                jQuery.each(formatCharacters, function (character, replacement) {
                    formattedDate = formattedDate.replace(
                        new RegExp(character, 'g'),
                        replacement
                    );
                });

                $input.val(formattedDate);

                // We can't change the input field value to something like "Thursday 16 March 2023", because this will cause an empty or invalid value in Powermail
                // However we do want to show this localized text on screen.
                var localTime = new Intl.DateTimeFormat('nl-NL', { dateStyle: 'full', timeStyle: 'short'}).format(selectedDate.getTime());

                $input.parent().find('.pika-chosen').remove();
                $input.after('<span class="pika-chosen">' + localTime.split('om')[0] + '</span>');
            }
        };

        if (window.location.hash && window.location.hash.indexOf(inputId) !== -1) {
            queryParts = window.location.hash.match(new RegExp(inputId + '=([0-9]+)'));

            if (queryParts && queryParts[1]) {
                try {
                    defaultValue.setTime(parseInt(queryParts[1], 10));
                } catch (ignore) {
                    // just ignore any error so the selected date will be today
                }
            }
        }

        picker = new Pikaday(options);

        // force selection of the current date
        picker.setDate(defaultValue);
    });
});
