/*global document, jQuery */
jQuery(document).ready(function ($) {
    'use strict';

    var
        checkbox = $('input[type="checkbox"]'),
        checkboxLabels = checkbox.parent().find('label'),
        radiobtn = $('input[type="radio"]'),
        radioLabels = radiobtn.parent().find('label'),
        select = $('select');

    if (!radioLabels.next().hasClass('customRadio')) {
        $('<a href="#" class="customRadio js-customInput" tabindex="-1"></a> ').insertAfter(radioLabels);
    }
    if (!checkboxLabels.next().hasClass('customCheckbox')) {
        $('<a href="#" class="customCheckbox js-customInput" tabindex="-1" data-test="form-checkbox-link"></a> ').insertAfter(checkboxLabels);
    }

    $('.js-customInput').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).parent().find('input[type="radio"], input[type="checkbox"]').trigger('click');
    });

    select.each(function () {
        $(this).wrap('<div class="customSelect"></div>');
    });
});
