document.addEventListener('DOMContentLoaded', () => {
    'use strict';

    const searchForm = document.querySelector('.pageHeader-search'),
          searchFormInput = document.querySelector('.searchForm-input'),
          closeButton = document.querySelector('.searchForm-close'),
          searchButtons = document.querySelectorAll('.js-navigationSearchButton');

    function handleSearchButtonClick(event) {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });

        searchForm?.classList.toggle('search-hidden');
        searchButtons.forEach((searchButton) => {
            searchButton.classList.toggle('is-active');
        });

        searchFormInput?.focus();
    }

    closeButton?.addEventListener('click', (event) => {
        event.preventDefault();
        searchForm.classList.add('search-hidden');

        searchButtons.forEach((searchButton) => {
            searchButton.classList.remove('is-active');
        });
    });

    searchButtons?.forEach((searchButton) => {
        searchButton.addEventListener('click', handleSearchButtonClick);
    });
});
